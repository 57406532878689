@import 'node_modules/bootstrap/scss/mixins';

/*****************************************
*       Background-image: cover mixin
******************************************/

@mixin bg-cover() {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/*****************************************
*       Media Query Mixins
******************************************/

// Specified breakpoint
@mixin media($name) {
  @include media-breakpoint-only($name) {
    @content;
  }
}

// Larger than specified breakpoint
@mixin media-up($name) {
  @include media-breakpoint-up($name) {
    @content;
  }
}

// smaller than specified breakpoint
@mixin media-down($name) {
  @include media-breakpoint-down($name) {
    @content;
  }
}

// /******************************************
// *           Placeholder
// *******************************************/
@mixin placeholder() {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    @content;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    @content;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    @content;
  }
}

// /******************************************
// *           Hardware Acceleration
// *******************************************/

@mixin accelerate() {
  transform: translate3d(0, 0, 0);
}
